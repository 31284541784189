import authReq from "../src/authRequest"
// utils
import { pacvueToken } from "@pacvue/utils"
import { jumpToRetailDashboard } from "./common"
// hooks
import { useAnalyticsTrack } from "../src/analytics"
// constants
import { isWhiteLabel, isCN, isEU, isProd } from "../constants/env"
// config
import { hostnameClientsMap } from "../config/white-label"

const { VITE_APP_USERURL, BASE_URL } = import.meta.env

const handleAuthCodeAndReloadPage = (from, to) => {
  return new Promise(async (resolve) => {
    sessionStorage.clear()
    if (to.query.authcode) {
      const productLine = to.query.query
      const searchParams = new URLSearchParams(window.location.search)
      let targetPath = window.location.pathname
      const api = searchParams.get("authType") === "SSO" ? "bySSOAuthCode" : "byCrossAuthCodev2"
      const res = await authReq({
        url: `${VITE_APP_USERURL}login/${api}?authCode=${to.query.authcode}`,
        method: "get",
        headers: {
          // 有伪产品线则优先使用伪产品线, 例如 Itsumo
          productline: window.pseudoProductLine?.toLowerCase?.() || productLine
        }
      }).catch((ex) => null)
      if (res?.data?.code == 212) {
        // 此处有 SSO => Multi Client 的可能
        // 去往 SSO 登录页的 Multi Client 选择
        targetPath = `${window.location.pathname}/login/loginwithsso`.replace("/SSL/INDEX", "/").replace("//", "/")
        searchParams.append("type", "MC")
        searchParams.delete("authType")
        // 结果存 local, 以便给 SSO 登录页使用
        localStorage.setItem("sso_mc_info", JSON.stringify(res))
        searchParams.delete("authcode")
        window.location.replace(`${targetPath}?${searchParams.toString()}`)
        resolve(true)
      } else if (res?.data?.code == 200) {
        pacvueToken.setPacvue_token(res)
        searchParams.delete("authcode")
        window.location.replace(`${targetPath}?${searchParams.toString()}`)
        resolve(true)
      }
    }
  })
}

/**
 * 一些特殊 Client 需要直接跳转他们的白名单地址 (groupM)
 * @param {Record<string, any>} { userStoreContext: userStore 信息, scenario: 跳转场景 (登录或 MyAccount 切换 Multi Clients) }
 * @returns {Promise<boolean>} 是否跳转
 */
const judgeClientAndRedirect = async ({ userStoreContext, scenario = "login" } = {}) => {
  const clientId = userStoreContext.user?.clientId?.toString?.()
  // public path 去除开头的 slash 作为 pathPrefix
  let pathPrefix = BASE_URL.substring(1)
  if (pathPrefix === "bol/") {
    pathPrefix = "bol-new/"
  }
  // product [US] => groupM [US] 定制跳转
  if (
    !isEU &&
    !isCN &&
    hostnameClientsMap["groupm.pacvue.com"].includes(clientId) &&
    ["product.pacvue.com", "v3-domain.pacvue.com"].includes(window.location.hostname) &&
    window.location.hostname !== "groupm.pacvue.com"
  ) {
    await jumpToRetailDashboard("https://groupm.pacvue.com", { isDomainUnified: false, removeHistory: scenario === "login", crossDomainLogin: scenario === "login" }, pathPrefix)
    return true
  }
  // groupM [US] => product [US] 定制回跳
  if (!isEU && !isCN && !hostnameClientsMap["groupm.pacvue.com"].includes(clientId) && window.location.hostname === "groupm.pacvue.com" && window.location.hostname !== "product.pacvue.com") {
    await jumpToRetailDashboard("https://product.pacvue.com", { isDomainUnified: false, removeHistory: scenario === "login", crossDomainLogin: scenario === "login" }, pathPrefix)
    return true
  }
  // product [EU] => groupM [EU] 定制跳转
  if (
    isEU &&
    hostnameClientsMap["groupmeurope.pacvue.com"].includes(clientId) &&
    ["producteurope.pacvue.com", "v3-domain-eu.pacvue.com"].includes(window.location.hostname) &&
    window.location.hostname !== "groupmeurope.pacvue.com"
  ) {
    await jumpToRetailDashboard("https://groupmeurope.pacvue.com", { isDomainUnified: false, removeHistory: scenario === "login", crossDomainLogin: scenario === "login" }, pathPrefix)
    return true
  }
  // groupM [EU] => product [EU] 定制回跳
  if (isEU && !hostnameClientsMap["groupmeurope.pacvue.com"].includes(clientId) && window.location.hostname === "groupmeurope.pacvue.com" && window.location.hostname !== "producteurope.pacvue.com") {
    await jumpToRetailDashboard("https://producteurope.pacvue.com", { isDomainUnified: false, removeHistory: scenario === "login", crossDomainLogin: scenario === "login" }, pathPrefix)
    return true
  }
  // TODO
  return false
}

let url = ""
const recursiveFindMenuPathByAlias = (menus, aliases) => {
  for (const subMenu of menus) {
    if (aliases.includes(subMenu.alias)) {
      url = subMenu.url
    } else if (subMenu.children?.length > 0) {
      recursiveFindMenuPathByAlias(subMenu.children, aliases)
    }
  }
  return url
}

const recordNavStartTime = () => {
  if ((!isWhiteLabel || window.location.hostname.includes("groupm")) && isProd) {
    sessionStorage.setItem("navStartTime", new Date().getTime())
  }
}

const logAndTrackNavDuration = ({ to, userInfo = {} } = {}) => {
  if (
    to.path !== "/Auth" &&
    !localStorage.getItem("signFlagBillingTag") &&
    sessionStorage.getItem("navStartTime") &&
    window.isCrossNav &&
    (!isWhiteLabel || window.location.hostname.includes("groupm")) &&
    isProd &&
    !userInfo?.byPacvuer
  ) {
    useAnalyticsTrack("Navigation Process Complete", {
      category: "Login",
      env: isCN ? "CN" : isEU ? "EU" : "US",
      "navigation duration": (new Date().getTime() - Number(sessionStorage.getItem("navStartTime"))) / 1000
    })
  }
  sessionStorage.removeItem("navStartTime")
  window.isCrossNav = null
  delete window.isCrossNav
}

export { handleAuthCodeAndReloadPage, judgeClientAndRedirect, recursiveFindMenuPathByAlias, recordNavStartTime, logAndTrackNavDuration }
