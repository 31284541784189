<template>
  <div class="pacvue-intelligenceGuide-wrap">
    <div class="pacvue-intelligenceGuide-header">
      <img style="background: #e3e3e3; width: 100%; height: 135px" src="https://pacvue-public-doc.s3.us-west-2.amazonaws.com/oss/pacvue-cdn/vue3/executive-guide.png" />
    </div>
    <div class="pacvue-intelligenceGuide-body p-5 pb-0">
      <div class="intelligenceGuide-body-title flex items-center gap-2">
        <!-- <el-icon :size="20" style="fill: #ffbf1a"><PacvueIconPacvueStarLine /></el-icon> -->
        {{ tipTitle }}
      </div>
      <div class="intelligenceGuide-body-inc">
        {{ tipContent }}
      </div>
      <div v-if="guideDom" class="intelligenceGuide-body-inc absolute bottom-[18px]">2/2</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
const tipTitle = $t("Navigation Update: Menu Merged")
const tipContent = $t(`Explore the new "Platform Intelligence" menu! This consolidated view combines the power of "Competitor Analysis" and "Category Intelligence" for a more streamlined experience.`)
const guideDom = ref(null)
onMounted(() => {
  guideDom.value = document.querySelector("#pacvue-siderMenu_CA_Competitive")
})
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.pacvue-intelligenceGuide-wrap {
  width: 500px;
  border-radius: 6px;
  overflow: hidden;
}
.intelligenceGuide-body-title {
  color: #45464f;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.intelligenceGuide-body-inc {
  color: #66666c;
  font-size: 12px;
  line-height: 18px;
}
.pacvue-hqGuideContent-wrapper {
  .driver-popover-footer {
    margin-bottom: 12px;
  }
  .driver-popover-tip {
    top: -9px !important;
    left: 66px;
  }
}
#driver-highlighted-element-stage {
  &.border-radius-12 {
    border-radius: 12px;
  }
}
</style>
